<template>
  <v-dialog v-model="dialog" style="z-index:1;" @keydown.esc="cancel" width="1000">
<!-- :max-width="options.width" -->
    <v-card>
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
      </v-toolbar>
      <div class="d-flex flex-nowrap" style="height:400px !important;">
        <v-tabs vertical v-model="familia" style="max-width: min-content;border-right: 3px solid var(--v-primary-base) !important;">
          <v-tab :key="i" v-for="f,i in familias" :value="i" :title="f.nombre" class="justify-start" style="z-index: 1000;">
            <span style="text-overflow: ellipsis !important;max-width: 100px !important;white-space: nowrap; text-align: left;overflow: hidden;">
              {{ f.nombre }}
            </span>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="familia" style="z-index: 10000;flex-grow: 1;">
          <v-tab-item v-for="f,i in familias" :value="i" :key="i">
            <v-simple-table height="400px" fixed-header style="overflow: auto; width: 100%;">
              <thead>
                <tr>
                  <th style="position: sticky !important; top: 0 !important; z-index: 1"><v-text-field placeholder="ARTÍCULO" @click:append="searchTerm=''" :append-icon="searchTerm ? 'mdi-close' : ''" dense v-model="searchTerm" hide-details=""></v-text-field></th>
                  <th style="position: sticky !important; top: 0 !important; z-index: 1">ESCANDALLO</th>
                  <th style="position: sticky !important; top: 0 !important; z-index: 1">DESCRIPCIÓN</th>
                  <th style="position: sticky !important; top: 0 !important; z-index: 1">PRECIO</th>
                </tr>
              </thead>
              <tbody>
                <tr
                style="cursor: pointer;"
                v-for="escandallo in escandallos.filter(esc => esc.familias.includes(f.idFamilia) && (esc.nombre.toLowerCase().includes(searchTerm.toLowerCase()) || articulos.filter(art => art.idArticulo == esc.idArticulo).map(a => a.nombre)[0].toLowerCase().includes(searchTerm.toLowerCase())))"
                @click.stop="agree(escandallo.idEscandallo)"
                >
                  <td>
                    {{ articulos.filter(art => art.idArticulo == escandallo.idArticulo).map(a => a.nombre)[0] }} 
                  </td>
                  <td style="white-space: nowrap !important;">
                    {{ escandallo.nombre }}
                  </td>
                  <td>
                    {{ escandallo.nota }}
                  </td>
                  <td style="white-space: nowrap !important;text-align: right;">
                    {{ parseFloat(pr(articulos.filter(art => art.idArticulo == escandallo.idArticulo).map(a => a.precio)[0], escandallo.formula) || 0).toFixed(2).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} €
                  </td>
                </tr>                
              </tbody>
            </v-simple-table>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props:{
    familias: Array,
    escandallos: Array,
    articulos: Array
  },
  data: () => ({
    rules: [v => !!v || "Este campo es obligatorio", v => !isNaN(v) || "Introduce un número"],
    searchTerm: '',
    familia: null,
    valid: true,
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      color: 'primary',
      width: 1700,
      zIndex: 200
    }
  }),
  methods: {
    pr(p,f){
      return eval(f.replaceAll("p",p))
    },
    open() {
      this.dialog = true
      this.title = "Añadir artículo"
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree(id) {
      this.resolve(id)
      this.dialog = false
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    }
  }
}
</script>